<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5 px-5">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-4 wow fadeInDown">
                        <h5>Pengaturan</h5>
                    </div>
                    <div class="col-sm-8 wow fadeInDown">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                            <li class="breadcrumb-item"><a href="/Validasi">Validasi Dokumen Perencanaan</a></li>
                            <li class="breadcrumb-item active">Pengaturan Validasi Dokumen Perencanaan</li>
                        </ol>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
            <div class="row" style="background-color: #ffffff;">
                <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                            href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                            aria-selected="true">
                            PERUNTUKAN PENGADAAN TANAH
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                            href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                            aria-selected="false">
                            MAKSUD DAN TUJUAN RENCANA PEMBANGUNAN
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-kesesuaian-tab" data-toggle="pill"
                            href="#custom-tabs-four-kesesuaian" role="tab" aria-controls="custom-tabs-four-kesesuaian"
                            aria-selected="false">
                            KESESUAIAN RTRW DAN PRIORITAS PEMBANGUNAN
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-letak-tab" data-toggle="pill"
                            href="#custom-tabs-four-letak" role="tab" aria-controls="custom-tabs-four-letak"
                            aria-selected="false">
                            LETAK TANAH
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-luas-tab" data-toggle="pill"
                            href="#custom-tabs-four-luas" role="tab" aria-controls="custom-tabs-four-luas"
                            aria-selected="false">
                            LUAS TANAH
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-gambaran-tab" data-toggle="pill"
                            href="#custom-tabs-four-gambaran" role="tab" aria-controls="custom-tabs-four-gambaran"
                            aria-selected="false">
                            GAMBARAN UMUM STATUS TANAH
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-waktu-tab" data-toggle="pill"
                            href="#custom-tabs-four-waktu" role="tab" aria-controls="custom-tabs-four-waktu"
                            aria-selected="false">
                            PERKIRAAN JANGKA WAKTU PENGADAAN TANAH
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-waktubangun-tab" data-toggle="pill"
                            href="#custom-tabs-four-waktubangun" role="tab" aria-controls="custom-tabs-four-waktubangun"
                            aria-selected="false">
                            PERKIRAAN JANGKA WAKTU PEMBANGUNAN
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-nilai-tab" data-toggle="pill"
                            href="#custom-tabs-four-nilai" role="tab" aria-controls="custom-tabs-four-nilai"
                            aria-selected="false">
                            PERKIRAAN NILAI TANAH
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-anggaran-tab" data-toggle="pill"
                            href="#custom-tabs-four-anggaran" role="tab" aria-controls="custom-tabs-four-anggaran"
                            aria-selected="false">
                            RENCANA ANGGARAN
                        </a>
                    </li>
                    <li class="nav-item" style="display: none;">
                        <a class="nav-link" id="custom-tabs-four-risk-tab" data-toggle="pill"
                            href="#custom-tabs-four-risk" role="tab" aria-controls="custom-tabs-four-risk"
                            aria-selected="false">
                            Kirim Data
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="custom-tabs-four-tabContent">
                    <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                        aria-labelledby="custom-tabs-four-home-tab">
                        <div class="row px-2">
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="container-fluid feature py-3">
                                <div class="section-title mb-0">
                                    <div class="sub-style">
                                        <h4 class="sub-title px-3 mb-0">
                                            DOKUMEN PENGADAAN TANAH
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    Peruntukkan Pengadaan Tanah: {{ peruntukkan }}
                                </label>
                            </div>
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    Keterangan: {{ keteranganperuntukkan }}
                                </label>
                            </div>
                            <!--IKON PETA 1-->
                            <div class="col-sm-12">
                                <label>
                                    Sketsa Peta Lokasi: {{ filenya_petaperuntukan }}
                                </label>
                            </div>
                            <div class="col-sm-12">
                                <label>
                                    Maksud Pengadaan Tanah: {{ maksud }}
                                </label>
                            </div>
                            <div class="col-sm-12">
                                <label>
                                    Keterangan Maksud: {{ keteranganmaksud }}
                                </label>
                            </div>
                            <div class="col-sm-12">
                                <label class="labelgray">Status Data</label>
                                <v-select :options="statusdata" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstatusdata" @update:modelValue="onstatusdataChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>

                            <!--IKON PETA 1 END-->

                            <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                            <div class="col-sm-4 text-center"></div>
                            <div class="col-sm-4 text-center">
                                <div class="custom-control custom-switch custom-switch-on-success">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                                    <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                                        style="font-size: small;">
                                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna
                                            Manusia</span>
                                        <span v-else>Human User Authentication</span>
                                    </label>
                                </div>
                                <button class="btn btn-danger2 btn-block text-white" placeholder="Username"
                                    data-toggle="tooltip" data-placement="left" title="Simpan Data.."
                                    style="color: black;" v-on:click="simpan()" :disabled="showHuman == false">
                                    <b><i class="fas fa-mail-bulk"></i>
                                        Simpan
                                    </b>
                                </button>
                                <button @click="batalproses()" class="btn btn-primary btn-block text-white"
                                    placeholder="Batal" data-toggle="tooltip" data-placement="left" title="Batal.."
                                    style="color: black;">
                                    <b><i class="fas fa-window-close"></i>
                                        Batal
                                    </b>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                        aria-labelledby="custom-tabs-four-profile-tab">
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        MAKSUD DAN TUJUAN RENCANA PEMBANGUNAN
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="help-block text-center">
                                Silahkan masukkan maksud dan tujuan rencana pembangunan
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="row px-2">
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    <li>Maksud Pengadaan Tanah <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="maksud" name="maksud"
                                        placeholder="Maksud Pengadaan Tanah" v-model="maksud">
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    <li>Keterangan Maksud<small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <quill-editor v-model:value="keteranganmaksud" id="keteranganmaksud"
                                            style="height: 25vh;background-color: #ffffff;"></quill-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    <li>Tujuan Pengadaan Tanah <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="tujuan" name="tujuan"
                                        placeholder="Tujuan Pengadaan Tanah" v-model="tujuan">
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                            <div class="col-sm-3 float-left pb-2"></div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="batalproses()" class="btn btn-primary btn-block text-white"
                                    placeholder="Batal" data-toggle="tooltip" data-placement="left" title="Batal.."
                                    style="color: black;">
                                    <b><i class="fas fa-window-close"></i>
                                        Batal
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="kembalikeperuntukkan()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Sebelumnya" data-toggle="tooltip" data-placement="left"
                                    title="Sebelumnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-left"></i>
                                        Sebelumnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="bukakesesuaian()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                    title="Selanjutnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-right"></i>
                                        Selanjutnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-3 float-left pb-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-kesesuaian" role="tabpanel"
                        aria-labelledby="custom-tabs-four-kesesuaian-tab">
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        KESESUAIAN RTRW DAN PRIORITAS PEMBANGUNAN
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="help-block text-center">
                                Silahkan masukkan Nomor RTRW Nasional, Provinsi, Kabupaten / Kota, RPJMN/RPJMD, Rencana
                                Strategis, Rencana Kerja Instansi
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="row px-2">
                            <div class="col-sm-6 float-left pb-2">
                                <label>
                                    <li>Nomor RTRW Nasional <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="rtrwnasional"
                                        name="rtrwnasional" placeholder="Nomor RTRW Nasional" v-model="rtrwnasional">
                                </div>
                            </div>
                            <div class="col-sm-6 float-left pb-2">
                                <label>
                                    <li>Nomor RPJMN / RPJMD <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="rpjmn" name="rpjmn"
                                        placeholder="Nomor RPJMN / RPJMD" v-model="rpjmn">
                                </div>
                            </div>
                            <div class="col-sm-6 float-left pb-2">
                                <label>
                                    <li>Nomor RTRW Provinsi <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="rtrwprov"
                                        name="rtrwprov" placeholder="Nomor RTRW Provinsi" v-model="rtrwprov">
                                </div>
                            </div>
                            <div class="col-sm-6 float-left pb-2">
                                <label>
                                    <li>Nomor Rencana Strategis <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="rencana" name="rencana"
                                        placeholder="Nomor Rencana Strategis" v-model="rencana">
                                </div>
                            </div>
                            <div class="col-sm-6 float-left pb-2">
                                <label>
                                    <li>Nomor RTRW Kab./Kota <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="rtrwkab" name="rtrwkab"
                                        placeholder="Nomor RTRW Kab./Kota " v-model="rtrwkab">
                                </div>
                            </div>
                            <div class="col-sm-6 float-left pb-2">
                                <label>
                                    <li>Nomor Rencana Kerja Instansi <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="rencanainstansi"
                                        name="rencanainstansi" placeholder="Nomor Rencana Kerja Instansi "
                                        v-model="rencanainstansi">
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    <li>Keterangan <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <quill-editor v-model:value="keteranganrtrw" id="keteranganrtrw"
                                            style="height: 25vh;background-color: #ffffff;"></quill-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                            <div class="col-sm-3 float-left pb-2"></div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="batalproses()" class="btn btn-primary btn-block text-white"
                                    placeholder="Batal" data-toggle="tooltip" data-placement="left" title="Batal.."
                                    style="color: black;">
                                    <b><i class="fas fa-window-close"></i>
                                        Batal
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="kembalikemaksud()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Sebelumnya" data-toggle="tooltip" data-placement="left"
                                    title="Sebelumnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-left"></i>
                                        Sebelumnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="bukaletak()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                    title="Selanjutnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-right"></i>
                                        Selanjutnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-3 float-left pb-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-letak" role="tabpanel"
                        aria-labelledby="custom-tabs-four-letak-tab">
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        LETAK TANAH
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="help-block text-center">
                                Silahkan pilih lokasi Kabupaten / Kota, Kecamatan dan Desa / Kelurahan
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="row px-2" v-for="(koodinatsetuju, counter) in koodinatsetujus" v-bind:key="counter">
                            <div class="col-sm-3">
                                <label>Kabupaten / Kota</label>
                                <select class="form-control form-control-sm" style="width: 100%;font-size: smaller;"
                                    :id="'kabkot' + (counter + 1)"
                                    @change="ambilkecamatan(counter + 1, 'kecamatan', 'kabkot')">
                                    <option v-for="(datakabkot, i) in kabkot" :value="datakabkot['tag']" :key="i"
                                        :selected="datakabkot['tag'] == koodinatsetuju.kabkot">
                                        {{ datakabkot['label'] }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-4">
                                <label>Kecamatan</label>
                                <select class="form-control form-control-sm" style="width: 100%;font-size: smaller;"
                                    :id="'kecamatan' + (counter + 1)"
                                    @change="ambildesa(counter + 1, 'kecamatan', 'kabkot', 'desa')">
                                    <option v-for="(datakecamatan, i) in kecamatan" :value="datakecamatan['code']"
                                        :key="i" :selected="datakecamatan['code'] == koodinatsetuju.kecamatan">
                                        {{ datakecamatan['label'] }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-3">
                                <label>Desa / Kelurahan</label>
                                <select class="form-control form-control-sm" style="width: 100%;font-size: smaller;"
                                    :id="'desa' + (counter + 1)">
                                    <option v-for="(datadesa, i) in desa" :value="datadesa['code']" :key="i"
                                        :selected="datadesa['code'] == koodinatsetuju.desa">
                                        {{ datadesa['label'] }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-2 text-center" v-if="(counter > 0)">
                                <label>Opsi</label><br>
                                <button type="button" class="btn btn-warning btn-sm" @click="addKoordinat"><i
                                        class="far fa-plus-square"></i></button>
                                <button type="button" class="btn btn-warning btn-sm"
                                    @click="deleteKoordinat(counter)"><i class="far fa-minus-square"></i></button>
                            </div>
                            <div class="col-sm-2 text-center" v-else>
                                <label>Opsi</label><br>
                                <button type="button" class="btn btn-warning btn-sm" @click="addKoordinat"><i
                                        class="far fa-plus-square"></i></button>
                            </div>
                        </div>
                        <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                        <div class="row px-2">
                            <div class="col-sm-12">
                                <quill-editor v-model:value="keteranganletaktanah" id="keteranganletaktanah"
                                    style="height: 25vh;background-color: #ffffff;"></quill-editor>
                            </div>
                        </div>
                        <div class="row px-2">
                            <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                            <div class="col-sm-3 float-left pb-2"></div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="batalproses()" class="btn btn-primary btn-block text-white"
                                    placeholder="Batal" data-toggle="tooltip" data-placement="left" title="Batal.."
                                    style="color: black;">
                                    <b><i class="fas fa-window-close"></i>
                                        Batal
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="kembalikekesesuaian()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Sebelumnya" data-toggle="tooltip" data-placement="left"
                                    title="Sebelumnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-left"></i>
                                        Sebelumnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="bukaluas()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                    title="Selanjutnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-right"></i>
                                        Selanjutnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-3 float-left pb-2"></div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="custom-tabs-four-luas" role="tabpanel"
                        aria-labelledby="custom-tabs-four-luas-tab">
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="container-fluid feature py-3">
                            <div class="section-title mb-0">
                                <div class="sub-style">
                                    <h4 class="sub-title px-3 mb-0">
                                        LUAS TANAH YANG DIBUTUHKAN
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="help-block text-center">
                                Silahkan masukkan luas tanah yang dibutuhkan
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="row px-2">
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    <li>Luas Tanah <small class="text-info"> (Ha* wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="luasha" name="luasha"
                                        placeholder="Luas Tanah (Ha)" v-model="luasha">
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    <li>Luas Tanah <small class="text-info"> (m<sup>2</sup> *wajib
                                            diisi)</small></li>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-user-circle"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="luasm" name="luasm"
                                        placeholder="Luas Tanah (m2)" v-model="luasm">
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">
                                <label>
                                    <li>Keterangan <small class="text-info"> (*wajib
                                            diisi)</small></li>
                                </label>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <quill-editor v-model:value="keteranganluastanah" id="keteranganluastanah"
                                            style="height: 25vh;background-color: #ffffff;"></quill-editor>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 float-left pb-2">&nbsp;</div>
                            <div class="col-sm-3 float-left pb-2"></div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="batalproses()" class="btn btn-primary btn-block text-white"
                                    placeholder="Batal" data-toggle="tooltip" data-placement="left" title="Batal.."
                                    style="color: black;">
                                    <b><i class="fas fa-window-close"></i>
                                        Batal
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="kembaliletak()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Sebelumnya" data-toggle="tooltip" data-placement="left"
                                    title="Sebelumnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-left"></i>
                                        Sebelumnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-2 float-left pb-2">
                                <button @click="bukagambaran()" class="btn btn-secondary btn-block text-white"
                                    placeholder="Selanjutnya" data-toggle="tooltip" data-placement="left"
                                    title="Selanjutnya.." style="color: black;">
                                    <b><i class="far fa-arrow-alt-circle-right"></i>
                                        Selanjutnya
                                    </b>
                                </button>
                            </div>
                            <div class="col-sm-3 float-left pb-2"></div>
                        </div>
                    </div>

                </div>

                <div class="col-sm-12">&nbsp;</div>
                <div class="col-sm-12">&nbsp;</div>

            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Select2 from 'vue3-select2-component';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor, Select2
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            peruntukkan: '',
            keteranganperuntukkan: '',
            filenya_petaperuntukan: '',
            maksud: '',
            tujuan: '',
            keteranganmaksud: '',
            rtrwnasional: '',
            rpjmn: '',
            rtrwprov: '',
            rencana: '',
            rtrwkab: '',
            rencanainstansi: '',
            keteranganrtrw: '',
            validasi: false,
            datapesan: '',
            counter: 0,
            koodinatsetujus: [{
                kabkot: '',
                kecamatan: '',
                desa: '',
                nilai1: '',
                nilai2: ''
            }],

            kabkot: [],
            datakabkot: [],
            defaultSelectedkabkot: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkabkot: 0,

            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkecamatan: 0,

            desa: [],
            datadesa: [],
            defaultSelecteddesa: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selecteddesa: 0,

            keteranganletaktanah: '',
            luasha: 0,
            luasm: 0,
            keteranganluastanah: '',

            tanahhm: 0,
            tanahhgu: 0,
            tanahhgb: 0,
            tanahhp: 0,
            tanahkas: 0,
            tanahnegara: 0,

            keterangangambaranumum: '',
            filenya_datasubjek: '',
            kantor: 0,
            tglkantor: '',
            keteranganoverlay: '',

            tglperencanaan1: '',
            tglperencanaan2: '',
            tglpersiapan1: '',
            tglpersiapan2: '',
            tglpelaksanaan1: '',
            tglpelaksanaan2: '',
            tglserah1: '',
            tglserah2: '',
            filenya_formatmatrik: '',
            keteranganwaktu: '',

            tglfisik1: '',
            tglfisik2: '',
            filenya_formatmatrikfisik: '',
            keteranganfisik: '',

            filenya_formatmatriknilai: '',
            keterangannilai: '',

            tahunanggaran: '',
            apbn: 0,
            apbdprov: 0,
            apbdkab: 0,
            apbn: 0,
            bumn: 0,
            bumd: 0,
            rpperencanaan: 0,
            rppersiapan: 0,
            rppelaksanaan: 0,
            rpserah: 0,
            keteranganrencanaanggaran: '',

            anggaran: [],
            dataanggaran: [],
            defaultSelectedanggaran: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedanggaran: 0,

            apbn: false,
            apbdprov: false,
            apbdkab: false,
            bumn: false,
            bumd: false,
            lain: false,

            nomorijin: '',
            tglijin: '',
            pejabatijin: '',
            filenya_formatfileijin: '',
            filenya_formatfilebelumijin: '',
            keteranganijin: '',
            showHuman: false,

            statusdata: [],
            datastatusdata: [],
            defaultSelectedstatusdata: {
                code: 0,
                label: 'Pilih Status Data',
                tag: 0,
            },
            selectedstatusdata: 0,

        }
    },
    methods: {
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        onstatusdataChange(a) {
            try {
                this.selectedstatusdata = this.statusdata[a].tag;
            }
            catch (err) {
                return;
            }
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        onanggaranChange(a) {
            try {
                this.selectedanggaran = this.anggaran[a].tag;
            }
            catch (err) {
                return;
            }
        },
        addKoordinat() {
            this.koodinatsetujus.push({
                kabkot: '',
                kecamatan: '',
                desa: '',
                nilai1: '',
                nilai2: '',
            })
        },
        deleteKoordinat(counter) {
            this.koodinatsetujus.splice(counter, 1);
        },
        ambilkecamatan(id, kolom, inisialkolom) {
            try {
                var kabkot = $('#' + inisialkolom + id).val();
                if ((kabkot != 0)) {
                    $('#' + kolom + id).empty();
                    var o = new Option("Pilih Salah Satu", "0");
                    $(o).html("Pilih Salah Satu");
                    $('#' + kolom + id).append(o);

                    $('#desa' + id).empty();
                    var o = new Option("Pilih Salah Satu", "0");
                    $(o).html("Pilih Salah Satu");
                    $('#desa' + id).append(o);

                    this.halamanloading = true;
                    var mainAPIDataReff = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    mainAPIDataReff.get("parastapainnovationLokasi-GetKecamatan?random=" + random + '&KodeKabkot=' + kabkot).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            if (this.datapesan == 'data diketemukan') {
                                this.datakecamatan = [];
                                this.kecamatan = [];
                                this.datakecamatan = Response.data.content.data;
                                this.datakecamatan.forEach((item) => {

                                    var o = new Option(item.Nama, item.Kode);
                                    $(o).html(item.Nama);
                                    $('#' + kolom + id).append(o);
                                });

                                this.desa = [];
                                this.desa.unshift({
                                    label: 'Pilih Salah Satu',
                                    code: 0,
                                    tag: 0,
                                });


                            }
                            this.halamanloading = false;
                        }
                    ).catch(error => {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    })
                }
                else {
                    $('#' + kolom + id).empty();
                    var o = new Option("Pilih Salah Satu", "0");
                    $(o).html("Pilih Salah Satu");
                    $('#' + kolom + id).append(o);

                    $('#desa' + id).empty();
                    var o = new Option("Pilih Salah Satu", "0");
                    $(o).html("Pilih Salah Satu");
                    $('#desa' + id).append(o);
                }

            } catch (error) {

            }

        },
        ambildesa(id, kecamatankolom, kabkotkolom, desakolom) {
            try {
                var kodekabkot = $('#' + kabkotkolom + id).val();
                var kodekecamatan = $('#' + kecamatankolom + id).val();
                $('#desa' + id).empty();
                var o = new Option("Pilih Salah Satu", "0");
                $(o).html("Pilih Salah Satu");
                $('#desa' + id).append(o);
                if ((kodekabkot != 0) && (kodekecamatan != 0)) {
                    this.halamanloading = true;
                    var mainAPIDataReff = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    var random = Math.random();
                    mainAPIDataReff.get("parastapainnovationLokasi-GetDesa?random=" + random + '&KodeKabkot=' + kodekabkot + "&KodeKecamatan=" + kodekecamatan).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            console.log(Response.data.content.data);
                            if (this.datapesan == 'data diketemukan') {
                                this.datadesa = [];
                                this.datadesa = Response.data.content.data;
                                this.datadesa.forEach((item) => {
                                    var o = new Option(item.Nama, item.Kode);
                                    $(o).html(item.Nama);
                                    $('#desa' + id).append(o);
                                });

                            }
                            this.halamanloading = false;
                            this.halamanloading = false;
                        }
                    ).catch(error => {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    })
                }
            } catch (error) {

            }

        },
        bukamaksud() {

            $('#custom-tabs-four-profile-tab').trigger('click');
        },
        kembalikeperuntukkan() {

            $('#custom-tabs-four-home-tab').trigger('click');
        },
        bukakesesuaian() {

            $('#custom-tabs-four-kesesuaian-tab').trigger('click');
        },
        kembalikemaksud() {

            $('#custom-tabs-four-profile-tab').trigger('click');
        },
        bukaletak() {
            for (let i = 0; i < this.koodinatsetujus.length; i++) {
                var element = document.getElementById('desa' + (i + 1));
                element.value = this.koodinatsetujus[i].desa;
            }
            $('#custom-tabs-four-letak-tab').trigger('click');
        },
        kembaliletak() {
            $('#custom-tabs-four-letak-tab').trigger('click');
        },
        bukaluas() {

            $('#custom-tabs-four-luas-tab').trigger('click');
        },
        kembalikekesesuaian() {

            $('#custom-tabs-four-kesesuaian-tab').trigger('click');
        },
        bukagambaran() {
            $('#custom-tabs-four-gambaran-tab').trigger('click');
        },
        kembalikeluas() {
            $('#custom-tabs-four-luas-tab').trigger('click');
        },
        bukawaktu() {
            $('#custom-tabs-four-waktu-tab').trigger('click');
        },
        kembalikegambaran() {
            $('#custom-tabs-four-gambaran-tab').trigger('click');
        },
        bukawaktubangun() {
            $('#custom-tabs-four-waktubangun-tab').trigger('click');
        },
        kembalikewaktu() {
            $('#custom-tabs-four-waktu-tab').trigger('click');
        },
        bukanilai() {
            $('#custom-tabs-four-nilai-tab').trigger('click');
        },
        kembalikewaktubangun() {
            $('#custom-tabs-four-waktubangun-tab').trigger('click');
        },
        bukaanggaran() {
            $('#custom-tabs-four-anggaran-tab').trigger('click');
        },
        kembalikenilai() {
            $('#custom-tabs-four-nilai-tab').trigger('click');
        },
        bukadampak() {
            $('#custom-tabs-four-risk-tab').trigger('click');
        },
        kembalikeanggaran() {
            $('#custom-tabs-four-anggaran-tab').trigger('click');
        },
        batalproses() {
            window.location.replace("/Validasi");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_petaperuntukan = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async AmbilDataReff() {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovationLokasi-GetLokasiNonKodeProvinsi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.Nama,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kabkot.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });

                        this.kecamatan.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });

                        this.desa.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPIDataReff.get("parastapainnovationReff-GetStatusData?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatusdata = [];
                        this.statusdata = [];
                        this.datastatusdata = Response.data.content.data;
                        this.datastatusdata.forEach((item) => {
                            this.statusdata.push({
                                label: item.Nama,
                                code: this.datastatusdata.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.statusdata.unshift({
                            label: 'Pilih Status Data',
                            code: 0,
                            tag: 0,
                        });
                        this.selectedstatusdata = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            await mainAPIDataReff.get("parastapainnovationReff-GetAnggaran?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataanggaran = [];
                        this.anggaran = [];
                        this.dataanggaran = Response.data.content.data;
                        this.dataanggaran.forEach((item) => {
                            this.anggaran.push({
                                label: item.Nama,
                                code: this.dataanggaran.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.anggaran.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });


                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovationPerencanaan-GetPerencanaanByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.peruntukkan = Response.data.content.data[0].peruntukkan;
                    this.keteranganperuntukkan = Response.data.content.data[0].keteranganperuntukkan;
                    this.filenya_petaperuntukan = Response.data.content.data[0].filenya_petaperuntukan;
                    this.maksud = Response.data.content.data[0].maksud;
                    this.tujuan = Response.data.content.data[0].tujuan;
                    this.keteranganmaksud = Response.data.content.data[0].keteranganmaksud;
                    this.rtrwnasional = Response.data.content.data[0].rtrwnasional;
                    this.rpjmn = Response.data.content.data[0].rpjmn;
                    this.rtrwprov = Response.data.content.data[0].rtrwprov;
                    this.rencana = Response.data.content.data[0].rencana;
                    this.rtrwkab = Response.data.content.data[0].rtrwkab;
                    this.rencanainstansi = Response.data.content.data[0].rencanainstansi;
                    this.keteranganrtrw = Response.data.content.data[0].keteranganrtrw;


                    var kota = Response.data.content.data[0].letakkab;
                    var kotatemp = kota.split(',');
                    var kecamatan = Response.data.content.data[0].letakkec;
                    var kecamatantemp = kecamatan.split(',');
                    var desa = Response.data.content.data[0].letakkel;
                    var desatemp = desa.split(',');

                    var nilai1 = Response.data.content.data[0].rp1;
                    var nilai1temp = nilai1.split(',');
                    var nilai2 = Response.data.content.data[0].rp2;
                    var nilai2temp = nilai2.split(',');

                    this.keteranganletaktanah = Response.data.content.data[0].keteranganletaktanah;
                    this.luasha = Response.data.content.data[0].luasha;
                    this.luasm = Response.data.content.data[0].luasm;
                    this.keteranganluastanah = Response.data.content.data[0].keteranganluastanah;

                    this.tanahhm = Response.data.content.data[0].tanahhm;
                    this.tanahhgu = Response.data.content.data[0].tanahhgu;
                    this.tanahhgb = Response.data.content.data[0].tanahhgb;
                    this.tanahhp = Response.data.content.data[0].tanahhp;
                    this.tanahkas = Response.data.content.data[0].tanahkas;
                    this.tanahnegara = Response.data.content.data[0].tanahnegara;

                    this.keterangangambaranumum = Response.data.content.data[0].keterangangambaranumum;
                    this.filenya_datasubjek = Response.data.content.data[0].filenya_datasubjek;
                    this.kantor = Response.data.content.data[0].kantor;
                    this.tglkantor = Response.data.content.data[0].tglkantor;
                    this.keteranganoverlay = Response.data.content.data[0].keteranganoverlay;
                    this.tglperencanaan1 = Response.data.content.data[0].tglperencanaan1;
                    this.tglperencanaan2 = Response.data.content.data[0].tglperencanaan2;

                    this.tglpersiapan1 = Response.data.content.data[0].tglpersiapan1;
                    this.tglpersiapan2 = Response.data.content.data[0].tglpersiapan2;
                    this.tglpelaksanaan1 = Response.data.content.data[0].tglpelaksanaan1;
                    this.tglpelaksanaan2 = Response.data.content.data[0].tglpelaksanaan2;

                    this.tglserah1 = Response.data.content.data[0].tglserah1;
                    this.tglserah2 = Response.data.content.data[0].tglserah2;
                    this.keteranganwaktu = Response.data.content.data[0].keteranganwaktu;
                    this.tglfisik1 = Response.data.content.data[0].tglfisik1;

                    this.filenya_formatmatrik = Response.data.content.data[0].filenya_formatmatrik;
                    this.tglfisik2 = Response.data.content.data[0].tglfisik2;
                    this.filenya_formatmatrikfisik = Response.data.content.data[0].filenya_formatmatrikfisik;
                    this.filenya_formatmatrikfisik = Response.data.content.data[0].filenya_formatmatrikfisik;
                    this.filenya_formatmatriknilai = Response.data.content.data[0].filenya_formatmatriknilai;
                    this.keterangannilai = Response.data.content.data[0].keterangannilai;

                    this.tahunanggaran = Response.data.content.data[0].tahunanggaran;
                    this.apbn = Response.data.content.data[0].apbn;
                    this.apbdprov = Response.data.content.data[0].apbdprov;
                    this.apbdkab = Response.data.content.data[0].apbdkab;
                    this.bumn = Response.data.content.data[0].bumn;
                    this.bumd = Response.data.content.data[0].bumd;
                    this.lain = Response.data.content.data[0].lain;

                    if (this.apbn == '1') {
                        document.getElementById('apbn').checked = true;
                    }
                    if (this.apbdprov == '1') {
                        document.getElementById('apbdprov').checked = true;
                    }
                    if (this.apbdkab == '1') {
                        document.getElementById('apbdkab').checked = true;
                    }
                    if (this.bumn == '1') {
                        document.getElementById('bumn').checked = true;
                    }
                    if (this.bumd == '1') {
                        document.getElementById('bumd').checked = true;
                    }
                    if (this.lain == '1') {
                        document.getElementById('lain').checked = true;
                    }

                    this.rpperencanaan = Response.data.content.data[0].rpperencanaan;
                    this.rppersiapan = Response.data.content.data[0].rppersiapan;
                    this.rppelaksanaan = Response.data.content.data[0].rppelaksanaan;
                    this.rpserah = Response.data.content.data[0].rpserah;
                    this.keteranganrencanaanggaran = Response.data.content.data[0].keteranganrencanaanggaran;

                    this.nomorijin = Response.data.content.data[0].nomorijin;
                    this.tglijin = Response.data.content.data[0].tglijin;
                    this.pejabatijin = Response.data.content.data[0].pejabatijin;
                    this.keteranganijin = Response.data.content.data[0].keteranganijin;
                    this.filenya_formatfileijin = Response.data.content.data[0].filenya_formatfileijin;
                    this.filenya_formatfilebelumijin = Response.data.content.data[0].filenya_formatfilebelumijin;

                    this.selectedstatusdata = Response.data.content.data[0].status1;
                    //alert(this.selectedstatusdata);
                    this.defaultSelectedstatusdata = {
                        code: Response.data.content.data[0].status1,
                        label: Response.data.content.data[0].namastatus,
                        tag: Response.data.content.data[0].status1,
                    };
                    this.defaultSelectedanggaran = {
                        code: Response.data.content.data[0].jenistahunanggaran,
                        label: Response.data.content.data[0].namajenistahunanggaran,
                        tag: Response.data.content.data[0].jenistahunanggaran,
                    };
                    this.selectedanggaran = Response.data.content.data[0].jenistahunanggaran;
                    //alert(kotatemp.length);
                    this.koodinatsetujus = [];
                    for (let i = 1; i <= kotatemp.length; i++) {
                        var mainAPIDataReff = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            },
                        });
                        var random = Math.random();
                        mainAPIDataReff.get("parastapainnovationLokasi-GetKecamatan?random=" + random + '&KodeKabkot=' + kotatemp[i - 1]).then(
                            Response => {
                                this.datapesan = Response.data.message;
                                if (this.datapesan == 'data diketemukan') {
                                    this.datakecamatan = [];
                                    this.kecamatan = [];
                                    this.datakecamatan = Response.data.content.data;
                                    this.datakecamatan.forEach((item) => {

                                        var o = new Option(item.Nama, item.Kode);
                                        $(o).html(item.Nama);
                                        $('#kecamatan' + i).append(o);
                                    });

                                    this.desa = [];
                                    this.desa.unshift({
                                        label: 'Pilih Salah Satu',
                                        code: 0,
                                        tag: 0,
                                    });


                                }
                                this.halamanloading = false;
                            }
                        ).catch(error => {
                            swal.fire('Peringatan', error, 'error');
                            this.halamanloading = false;
                            return false;
                        })
                        //alert(kecamatantemp[i - 1]);
                        mainAPIDataReff.get("parastapainnovationLokasi-GetDesa?random=" + random + '&KodeKabkot=' + kotatemp[i - 1] + "&KodeKecamatan=" + kecamatantemp[i - 1]).then(
                            Response => {
                                this.datapesan = Response.data.message;
                                //console.log(Response.data.content.data);
                                if (this.datapesan == 'data diketemukan') {
                                    this.datadesa = [];
                                    this.datadesa = Response.data.content.data;
                                    this.datadesa.forEach((item) => {
                                        var o = new Option(item.Nama, item.Kode);
                                        $(o).html(item.Nama);
                                        $('#desa' + i).append(o);
                                    });

                                }

                                this.halamanloading = false;
                            }
                        ).catch(error => {
                            swal.fire('Peringatan', error, 'error');
                            this.halamanloading = false;
                            return false;
                        })
                        //alert(desatemp[i - 1]);

                        this.koodinatsetujus.push({
                            kabkot: kotatemp[i - 1],
                            kecamatan: kecamatantemp[i - 1],
                            desa: desatemp[i - 1],
                            nilai1: nilai1temp[i - 1],
                            nilai2: nilai2temp[i - 1],
                        });
                        //alert(JSON.stringify(this.koodinatsetujus[0]));
                        //document.getElementById('desa'+i).value = desatemp[i - 1];
                    }

                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var kabkot = '';
            var kecamatan = '';
            var desa = '';
            var nilai1 = '';
            var nilai2 = '';

            var apbn = 0;
            var apbdprov = 0;
            var apbdkab = 0;
            var bumn = 0;
            var bumd = 0;
            var lain = 0;

            if (document.getElementById('apbn').checked == true) {
                apbn = 1;
            }
            if (document.getElementById('apbdprov').checked == true) {
                apbdprov = 1;
            }
            if (document.getElementById('apbdkab').checked == true) {
                apbdkab = 1;
            }
            if (document.getElementById('bumn').checked == true) {
                bumn = 1;
            }
            if (document.getElementById('bumd').checked == true) {
                bumd = 1;
            }
            if (document.getElementById('lain').checked == true) {
                lain = 1;
            }

            for (let i = 0; i < this.koodinatsetujus.length; i++) {
                if (kabkot == "") {
                    kabkot = kabkot + document.getElementById("kabkot" + (i + 1)).value;
                }
                else {
                    kabkot = kabkot + "," + document.getElementById("kabkot" + (i + 1)).value;
                }

                if (kecamatan == "") {
                    kecamatan = kecamatan + document.getElementById("kecamatan" + (i + 1)).value;
                }
                else {
                    kecamatan = kecamatan + "," + document.getElementById("kecamatan" + (i + 1)).value;
                }

                if (desa == "") {
                    desa = desa + document.getElementById("desa" + (i + 1)).value;
                }
                else {
                    desa = desa + "," + document.getElementById("desa" + (i + 1)).value;
                }

                if (nilai1 == "") {
                    nilai1 = nilai1 + document.getElementById("nilai1" + (i + 1)).value;
                }
                else {
                    nilai1 = nilai1 + "," + document.getElementById("nilai1" + (i + 1)).value;
                }

                if (nilai2 == "") {
                    nilai2 = nilai2 + document.getElementById("nilai2" + (i + 1)).value;
                }
                else {
                    nilai2 = nilai2 + "," + document.getElementById("nilai2" + (i + 1)).value;
                }
            }
            //alert(this.tahunanggaran);
            //return false;
            var fd = new FormData();
            fd.append("peruntukkan", this.peruntukkan);
            fd.append("keteranganperuntukkan", this.keteranganperuntukkan);
            fd.append("maksud", this.maksud);
            fd.append("tujuan", this.tujuan);
            fd.append("keteranganmaksud", this.keteranganmaksud);
            fd.append("rtrwnasional", this.rtrwnasional);
            fd.append("rpjmn", this.rpjmn);
            fd.append("rtrwprov", this.rtrwprov);
            fd.append("rencana", this.rencana);
            fd.append("rtrwkab", this.rtrwkab);
            fd.append("rencanainstansi", this.rencanainstansi);
            fd.append("keteranganrtrw", this.keteranganrtrw);
            fd.append("letakkab", kabkot);
            fd.append("letakkec", kecamatan);
            fd.append("letakkel", desa);
            fd.append("keteranganletaktanah", this.keteranganletaktanah);
            fd.append("luasha", this.luasha);
            fd.append("luasm", this.luasm);
            fd.append("keteranganluastanah", this.keteranganluastanah);
            fd.append("tanahhm", this.tanahhm);
            fd.append("tanahhgu", this.tanahhgu);
            fd.append("tanahhgb", this.tanahhgb);
            fd.append("tanahhp", this.tanahhp);
            fd.append("tanahkas", this.tanahkas);
            fd.append("tanahnegara", this.tanahnegara);
            fd.append("keterangangambaranumum", this.keterangangambaranumum);
            fd.append("kantor", this.kantor);
            fd.append("tglkantor", this.tglkantor);
            fd.append("keteranganoverlay", this.keteranganoverlay);
            fd.append("tglperencanaan1", this.tglperencanaan1);
            fd.append("tglperencanaan2", this.tglperencanaan2);
            fd.append("tglpersiapan1", this.tglpersiapan1);
            fd.append("tglpersiapan2", this.tglpersiapan2);
            fd.append("tglpelaksanaan1", this.tglpelaksanaan1);
            fd.append("tglpelaksanaan2", this.tglpelaksanaan2);
            fd.append("tglserah1", this.tglserah1);
            fd.append("tglserah2", this.tglserah2);
            fd.append("keteranganwaktu", this.keteranganwaktu);
            fd.append("tglfisik1", this.tglfisik1);
            fd.append("tglfisik2", this.tglfisik2);
            fd.append("keteranganfisik", this.keteranganfisik);
            fd.append("rp1", nilai1);
            fd.append("rp2", nilai2);
            fd.append("filenya_formatmatriknilai", this.filenya_formatmatriknilai);
            fd.append("keterangannilai", this.keterangannilai);
            fd.append("tahunanggaran", this.tahunanggaran);
            fd.append("jenistahunanggaran", this.selectedanggaran);
            fd.append("apbn", apbn);
            fd.append("apbdprov", apbdprov);
            fd.append("apbdkab", apbdkab);
            fd.append("bumn", bumn);
            fd.append("bumd", bumd);
            fd.append("lain", lain);
            fd.append("rpperencanaan", this.rpperencanaan);
            fd.append("rppersiapan", this.rppersiapan);
            fd.append("rppelaksanaan", this.rppelaksanaan);
            fd.append("rpserah", this.rpserah);
            fd.append("keteranganrencanaanggaran", this.keteranganrencanaanggaran);
            fd.append("nomorijin", this.nomorijin);
            fd.append("tglijin", this.tglijin);
            fd.append("pejabatijin", this.pejabatijin);
            fd.append("keteranganijin", this.keteranganijin);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("status1", this.selectedstatusdata);
            
            fd.append("id", this.secretencData);

            if (this.secretencData != 'Tambah') {
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("parastapainnovationPerencanaan-UpdatePerencanaan", fd).then(
                    Response => {
                        if (Response.data.response == 'error') {
                            swal.fire('Peringatan', Response.data.message, 'error');
                        }
                        else {
                            swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                window.location.replace("/Validasi");
                            });
                        }
                    }
                ).catch(function (error) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error
                    });
                }
                )
            }
            else {
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("parastapainnovationPerencanaan-AddPerencanaan", fd).then(
                    Response => {
                        if (Response.data.response == 'error') {
                            swal.fire('Peringatan', Response.data.message, 'error');
                        }
                        else {
                            swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                window.location.replace("/Perencanaan");
                            });
                        }
                    }
                ).catch(function (error) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error
                    });
                }
                )
            }

        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("konten", this.konten);
            fd.append("konten_eng", this.konten_eng);
            fd.append("filenya_petaperuntukan", document.getElementById('filenya_petaperuntukan').value);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_beranda-UpdatePerencanaan", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/Perencanaan");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.AmbilDataReff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }

    }
}
</script>
<style></style>