<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Perencanaan Pengadaan Tanah</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Perencanaan Pengadaan Tanah</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card">
                                <div id="dataheader">
                                    <label class="p-2 text-white">Filter Data</label>
                                    <div class="card-tools float-right">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"
                                            @click="toggleShow">
                                            <i :class="{ 'fas fa-minus': showFilter, 'fas fa-plus': !showFilter }"
                                                style="color: aliceblue;"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-2">
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Tahun Data</label>
                                        <v-select :options="tahun" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedtahun" @update:modelValue="ontahunChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Kab./Kota</label>
                                        <v-select :options="kabkot" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedkabkot" @update:modelValue="onkabkotChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Kecamatan</label>
                                        <v-select :options="kecamatan" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedkecamatan" @update:modelValue="onkecamatanChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Desa / Kelurahan</label>
                                        <v-select :options="desa" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelecteddesa" @update:modelValue="ondesaChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Status Data</label>
                                        <v-select :options="statusdata" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedstatusdata" @update:modelValue="onstatusdataChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Opsi</label><br>
                                        <button type="button" class="btn btn-primary btn-sm text-white lebar2"
                                            @click="caridata()" data-toggle="tooltip" data-placement="left"
                                            title="Cari data..">
                                            <i class="fas fa-search"></i> Cari Data
                                        </button>
                                    </div>

                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 wow fadeInUp">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-3 float-left pb-2" v-if="tutupdata == 'false'">
                                        <button type="button"
                                            class="btn btn-primary btn-success3 btn-sm text-white lebar2"
                                            @click="prosesdata('Tambah')" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fas fa-plus"></i> Tambah
                                        </button>
                                    </div>
                                    <div class="col-sm-8 float-left pb-2">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 30px;">No</th>
                                                    <th style="width: 50px;">Tahun</th>
                                                    <th style="width: 80px;">Kabupaten / Kota<br>Kecamatan<br>Desa /
                                                        Kelurahan</th>
                                                    <th style="width: 80px;">Peruntukkan</th>
                                                    <th style="width: 80px;">Maksud</th>
                                                    <th style="width: 80px;">Tujuan</th>
                                                    <th style="width: 80px;">Tahapan</th>
                                                    <th style="width: 130px;">Opsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td>
                                                            {{ datalist.tahun }}
                                                        </td>
                                                        <td>
                                                            Kab./Kota: {{ datalist.NMKabkot }}<br>Kecamatan: {{
                                                                datalist.NMKECAMATAN }}<br>Desa: {{
                                                                datalist.NMDESA }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.peruntukkan }}
                                                        </td>
                                                        <td>
                                                            {{ removeHTMLTags(datalist.maksud) }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.tujuan }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ datalist.NamaTahapan }}
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button" v-if="tutupdata == 'false'"
                                                                class="btn btn-primary3 btn-sm lebar2 text-white"
                                                                @click="prosesdata(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Edit data.."><i
                                                                    class="fas fa-edit"></i> Edit Data</button>
                                                            <button type="button" v-if="tutupdata == 'false'"
                                                                class="btn btn-info3 btn-sm lebar2 text-white"
                                                                @click="hapus(datalist.id)" data-toggle="tooltip"
                                                                data-placement="left" title="Hapus data.."><i
                                                                    class="fas fa-trash-alt"></i> Hapus Data</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    <td colspan="9">Data masih kosong</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            showFilter: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kabkotuser: JSON.parse(localStorage.getItem("bdlsistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            kabkot: [],
            datakabkot: [],
            defaultSelectedkabkot: {
                code: 0,
                label: 'Semua Kabupaten / Kota',
                tag: 0,
            },
            selectedkabkot: 0,

            kecamatan: [],
            datakecamatan: [],
            defaultSelectedkecamatan: {
                code: 0,
                label: 'Semua Kecamatan',
                tag: 0,
            },
            selectedkecamatan: 0,

            desa: [],
            datadesa: [],
            defaultSelecteddesa: {
                code: 0,
                label: 'Semua Desa / Kelurahan',
                tag: 0,
            },
            selecteddesa: 0,

            statusdata: [],
            datastatusdata: [],
            defaultSelectedstatusdata: {
                code: 0,
                label: 'Semua Status Data',
                tag: 0,
            },
            selectedstatusdata: 0,

            tahun: [],
            datatahun: [],
            defaultSelectedtahun: {
                code: new Date().getFullYear(),
                label: new Date().getFullYear(),
                tag: new Date().getFullYear(),
            },
            selectedtahun: new Date().getFullYear(),
            tutupdata: false,
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        toggleShow() {
            this.showFilter = !this.showFilter;
        },
        onkabkotChange(a) {
            try {
                this.selectedkabkot = this.kabkot[a].tag;
                this.ambilkecamatan();
                this.ambildesa();
            }
            catch (err) {
                return;
            }
        },
        onkecamatanChange(a) {
            try {
                this.selectedkecamatan = this.kecamatan[a].tag;
                this.ambildesa();
            }
            catch (err) {
                return;
            }
        },
        ondesaChange(a) {
            try {
                this.selecteddesa = this.desa[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatusdataChange(a) {
            try {
                this.selectedstatusdata = this.statusdata[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.tahun[a].tag;
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambilkecamatan() {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetKecamatan?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Nama,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Semua Kecamatan',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelectedkecamatan = {
                            code: 0,
                            label: 'Pilih Kecamatan',
                            tag: 0,
                        };
                        this.selectedkecamatan = 0;
                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Desa / Kelurahan',
                            tag: 0,
                        };
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async ambildesa() {
            this.halamanloading = true;
            var random = Math.random();
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetDesa?KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.Nama,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.desa.unshift({
                            label: 'Semua Desa / Kelurahan',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelecteddesa = {
                            code: 0,
                            label: 'Pilih Desa / Kelurahan',
                            tag: 0,
                        };
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPISETTING = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPISETTING.get("parastapainnovation_setting-Data?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.tutupdata = Response.data.content.data[0].Nama;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var Paramkabkot = this.kabkotuser;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            await mainAPI.get("parastapainnovationLokasi-GetLokasiNonKodeProvinsi?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakabkot = [];
                        this.kabkot = [];
                        this.datakabkot = Response.data.content.data;
                        this.datakabkot.forEach((item) => {
                            this.kabkot.push({
                                label: item.Nama,
                                code: this.datakabkot.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                            this.kabkot.unshift({
                                label: 'Semua Kabupaten / Kota',
                                code: 0,
                                tag: 0,
                            });
                            this.selectedkabkot = 0;
                        }
                        else {
                            this.datakabkot.forEach((item) => {
                                this.defaultSelectedkabkot = {
                                    code: item.Kode,
                                    label: item.Nama,
                                    tag: item.Kode,
                                };
                            });
                            this.selectedkabkot = this.kabkotuser;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationLokasi-GetKecamatan?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakecamatan = [];
                        this.kecamatan = [];
                        this.datakecamatan = Response.data.content.data;
                        this.datakecamatan.forEach((item) => {
                            this.kecamatan.push({
                                label: item.Nama,
                                code: this.datakecamatan.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.kecamatan.unshift({
                            label: 'Semua Kecamatan',
                            code: 0,
                            tag: 0,
                        });
                        this.selectedkecamatan = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationLokasi-GetDesa?KodeKabkot=" + Paramkabkot + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadesa = [];
                        this.desa = [];
                        this.datadesa = Response.data.content.data;
                        this.datadesa.forEach((item) => {
                            this.desa.push({
                                label: item.Nama,
                                code: this.datadesa.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.desa.unshift({
                            label: 'Semua Desa / Kelurahan',
                            code: 0,
                            tag: 0,
                        });
                        this.selecteddesa = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationReff-GetStatusData?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatusdata = [];
                        this.statusdata = [];
                        this.datastatusdata = Response.data.content.data;
                        this.datastatusdata.forEach((item) => {
                            this.statusdata.push({
                                label: item.Nama,
                                code: this.datastatusdata.indexOf(item) + 1,
                                tag: item.Kode,
                            });
                        });
                        this.statusdata.unshift({
                            label: 'Semua Status Data',
                            code: 0,
                            tag: 0,
                        });
                        this.selectedstatusdata = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            await mainAPI.get("parastapainnovationPerencanaan-GetTahunData?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatahun = [];
                        this.tahun = [];
                        this.datatahun = Response.data.content.data;
                        this.datatahun.forEach((item) => {
                            this.tahun.push({
                                label: item.Tahun,
                                code: this.datatahun.indexOf(item),
                                tag: item.Tahun,
                            });

                            this.defaultSelectedtahun = {
                                code: item.Tahun,
                                label: item.Tahun,
                                tag: item.Tahun,
                            };
                            this.selectedtahun = item.Tahun;
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

            this.halamanloading = true;
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            var Paramdesa = this.selecteddesa;
            if ((Paramdesa == '0') || (Paramdesa == '')) {
                Paramdesa = '';
            }
            var Paramstatusdata = this.selectedstatusdata;
            if ((Paramstatusdata == '0') || (Paramstatusdata == '')) {
                Paramstatusdata = '';
            }
            var Paramstahun = this.selectedtahun;
            await mainAPI.get("parastapainnovationPerencanaan-GetDataPerencanaan?random=" + random + "&Tahun=" + Paramstahun + "&KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&KodeDesa=" + Paramdesa + "&StatusData=" + Paramstatusdata).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.selectedtahun = this.selectedtahun;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async caridata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var Paramkabkot = this.selectedkabkot;
            if ((Paramkabkot == '33') || (Paramkabkot == '0') || (Paramkabkot == '')) {
                Paramkabkot = '';
            }
            var Paramkecamatan = this.selectedkecamatan;
            if ((Paramkecamatan == '0') || (Paramkecamatan == '')) {
                Paramkecamatan = '';
            }
            var Paramdesa = this.selecteddesa;
            if ((Paramdesa == '0') || (Paramdesa == '')) {
                Paramdesa = '';
            }
            var Paramstatusdata = this.selectedstatusdata;
            if ((Paramstatusdata == '0') || (Paramstatusdata == '')) {
                Paramstatusdata = '';
            }
            var Paramstahun = this.selectedtahun;
            await mainAPI.get("parastapainnovationPerencanaan-GetDataPerencanaan?random=" + random + "&Tahun=" + Paramstahun + "&KodeKabkot=" + Paramkabkot + "&KodeKecamatan=" + Paramkecamatan + "&KodeDesa=" + Paramdesa + "&StatusData=" + Paramstatusdata).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.selectedtahun = this.selectedtahun;
                    }
                    else {
                        this.datamaster = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesPerencanaan/" + bytes);
        },
        lihatdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ViewPerencanaan/" + bytes);
        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#2176e6",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovationPerencanaan-DeletePerencanaan", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/Perencanaan");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>